import React from "react"
// import styled from "@emotion/styled"

import PageContent from "../components/shared/PageContent"
import TransError from "../components/shared/TransError"
import { TLDR } from "../components/shared/Typography"
import SEO from "../components/SEO"

const PrivacyPolicy = () => (
  <>
    <SEO title="Privacy Policy" />
    <TransError />
    <PageContent>
      <h1>Privacy Policy</h1>
      <TLDR>
        <span>TLDR</span>
        <div>
          We use Google Analytics like most websites. If you have an issue with
          this,{" "}
          <a href="https://www.google.com/intl/policies/privacy/">
            please contact Google
          </a>
        </div>
        .
      </TLDR>
      <div className="policy-wrapper">
        <section>
          <h3>Owner and Data Controller</h3>
          <p>Language Guide</p>
          <p>
            <b>Owner contact email:</b>{" "}
            <a href="mailto:privacy@languagestudyguides.com">
              privacy@languagestudyguides.com
            </a>
          </p>
        </section>
        <br />
        <section>
          <h3>Types of Data Collected</h3>
          <p>
            Among the types of Personal Data that this Application collects, by
            itself or through third parties, there are: email address, password,
            Cookies, Usage Data, Data communicated while using the service,
            first name, last name, email and various types of Data.
          </p>
          <p>
            Complete details on each type of Personal Data collected are
            provided in the dedicated sections of this privacy policy or by
            specific explanation texts displayed prior to the Data collection.
          </p>
          <p>
            Personal Data may be freely provided by the User, or, in case of
            Usage Data, collected automatically when using this Application.
          </p>
          <p>
            Unless specified otherwise, all Data requested by this Application
            is mandatory and failure to provide this Data may make it impossible
            for this Application to provide its services. In cases where this
            Application specifically states that some Data is not mandatory,
            Users are free not to communicate this Data without consequences to
            the availability or the functioning of the Service. Users who are
            uncertain about which Personal Data is mandatory are welcome to
            contact the Owner.
          </p>
          <p>
            Unless specified otherwise, all Data requested by this Application
            is mandatory and failure to provide this Data may make it impossible
            for this Application to provide its services. In cases where this
            Application specifically states that some Data is not mandatory,
            Users are free not to communicate this Data without consequences to
            the availability or the functioning of the Service. Users who are
            uncertain about which Personal Data is mandatory are welcome to
            contact the Owner.
          </p>
          <p>
            Unless specified otherwise, all Data requested by this Application
            is mandatory and failure to provide this Data may make it impossible
            for this Application to provide its services. In cases where this
            Application specifically states that some Data is not mandatory,
            Users are free not to communicate this Data without consequences to
            the availability or the functioning of the Service. Users who are
            uncertain about which Personal Data is mandatory are welcome to
            contact the Owner.
          </p>
          <p>
            Any use of Cookies – or of other tracking tools – by this
            Application or by the owners of third-party services used by this
            Application serves the purpose of providing the Service required by
            the User, in addition to any other purposes described in the present
            document and in the Cookie Policy, if available.
          </p>
          <p>
            Users are responsible for any third-party Personal Data obtained,
            published or shared through this Application and confirm that they
            have the third party’s consent to provide the Data to the Owner.
          </p>
        </section>
        <section>
          <h3>Mode and Place of Processing the Data</h3>
          <h5>Methods of Processing</h5>
          <p>
            The Owner takes appropriate security measures to prevent
            unauthorized access, disclosure, modification, or unauthorized
            destruction of the Data. The Data processing is carried out using
            computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In
            addition to the Owner, in some cases, the Data may be accessible to
            certain types of persons in charge, involved with the operation of
            this Application (administration, sales, marketing, legal, system
            administration) or external parties (such as third-party technical
            service providers, mail carriers, hosting providers, IT companies,
            communications agencies) appointed, if necessary, as Data Processors
            by the Owner. The updated list of these parties may be requested
            from the Owner at any time.
          </p>
          <h5>Legal Basis of Processing</h5>
          <p>
            The Owner may process Personal Data relating to Users if one of the
            following applies:
          </p>
          <ul>
            <li>
              Users have given their consent for one or more specific purposes.
              Note: Under some legislations the Owner may be allowed to process
              Personal Data until the User objects to such processing
              (“opt-out”), without having to rely on consent or any other of the
              following legal bases. This, however, does not apply, whenever the
              processing of Personal Data is subject to European data protection
              law;
            </li>
            <li>
              provision of Data is necessary for the performance of an agreement
              with the User and/or for any pre-contractual obligations thereof;
            </li>
            <li>
              processing is necessary for compliance with a legal obligation to
              which the Owner is subject;
            </li>
            <li>
              processing is related to a task that is carried out in the public
              interest or in the exercise of official authority vested in the
              Owner;
            </li>
            <li>
              processing is necessary for the purposes of the legitimate
              interests pursued by the Owner or by a third party.
            </li>
          </ul>
          <p>
            In any case, the Owner will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>
          <h5>Place</h5>
          <p>
            The Data is processed at the Owner’s operating offices and in any
            other places where the parties involved in the processing are
            located.
          </p>
          <p>
            Depending on the User’s location, data transfers may involve
            transferring the User’s Data to a country other than their own. To
            find out more about the place of processing of such transferred
            Data, Users can check the section containing details about the
            processing of Personal Data.
          </p>
          <p>
            Users are also entitled to learn about the legal basis of Data
            transfers to a country outside the European Union or to any
            international organization governed by public international law or
            set up by two or more countries, such as the UN, and about the
            security measures taken by the Owner to safeguard their Data.
          </p>
          <p>
            If any such transfer takes place, Users can find out more by
            checking the relevant sections of this document or inquire with the
            Owner using the information provided in the contact section.
          </p>
          <h5>Retention Time</h5>
          <p>
            Personal Data shall be processed and stored for as long as required
            by the purpose they have been collected for. Therefore:
          </p>
          <ul>
            <li>
              Personal Data collected for purposes related to the performance of
              a contract between the Owner and the User shall be retained until
              such contract has been fully performed.
            </li>
            <li>
              Personal Data collected for the purposes of the Owner’s legitimate
              interests shall be retained as long as needed to fulfill such
              purposes. Users may find specific information regarding the
              legitimate interests pursued by the Owner within the relevant
              sections of this document or by contacting the Owner.
            </li>
          </ul>
          <p>
            The Owner may be allowed to retain Personal Data for a longer period
            whenever the User has given consent to such processing, as long as
            such consent is not withdrawn. Furthermore, the Owner may be obliged
            to retain Personal Data for a longer period whenever required to do
            so for the performance of a legal obligation or upon order of an
            authority.
          </p>
          <p>
            Once the retention period expires, Personal Data shall be deleted.
            Therefore, the right to access, the right to erasure, the right to
            rectification and the right to data portability cannot be enforced
            after expiration of the retention period.
          </p>
        </section>
        <section>
          <h3>The Purpose of Processing</h3>
          <p>
            The Data concerning the User is collected to allow the Owner to
            provide its Services, as well as for the following purposes:
            Contacting the User, Registration and authentication, Access to
            third party services’ accounts, Analytics, Interaction with support
            and feedback platforms, Managing support and contact requests,
            Displaying content from external platforms, Managing contacts and
            sending messages, Hosting and backend infrastructure, Remarketing
            and behavioral targeting, Handling payments, Interaction with
            external social networks and platforms, Interaction with live chat
            platforms, Infrastructure monitoring, User database management,
            Commercial affiliation, Tag Management and Data transfer outside the
            EU.
          </p>
          <p>
            Users can find further detailed information about such purposes of
            processing and about the specific Personal Data used for each
            purpose in the respective sections of this document.
          </p>
        </section>
        <section className="pp-detailed">
          <h3>Detailed Information on the Processing of Personal Data</h3>
          <div>
            <h4>Analytics</h4>
            <h5>
              Google Analytics is a web analysis service provided by Google Inc.
              (“Google”).
            </h5>
            <p>
              Google utilizes the Data collected to track and examine the use of
              this Application, to prepare reports on its activities and share
              them with other Google services.
            </p>
            <p>
              Google may use the Data collected to contextualize and personalize
              the ads of its own advertising network. This integration of Google
              Analytics anonymizes your IP address. It works by shortening
              Users' IP addresses within member states of the European Union or
              in other contracting states to the Agreement on the European
              Economic Area. Only in exceptional cases will the complete IP
              address be sent to a Google server and shortened within the US.
            </p>
            <p>Personal Data collected: Cookies and Usage Data.</p>
            <p>
              Place of processing: United States –{" "}
              <a
                target="_blank"
                href="https://www.google.com/intl/policies/privacy/"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{" "}
              –{" "}
              <a
                target="_blank"
                href="https://tools.google.com/dlpage/gaoptout"
                rel="noopener noreferrer"
              >
                Opt Out
              </a>
              . Privacy Shield participant.
            </p>
          </div>
          <div>
            <h4>Contacting the User</h4>
            <h5>Mailing List or Newsletter (This Application)</h5>
            <p>
              By registering on the mailing list or for the newsletter, the
              User’s email address will be added to the contact list of those
              who may receive email messages containing information of
              commercial or promotional nature concerning this Application. Your
              email address might also be added to this list as a result of
              signing up to this Application or after making a purchase.
            </p>
            <p>Personal Data collected: email address.</p>
          </div>
          <div>
            <h4>Hosting and Backend Infrastructure</h4>
            <p>
              This type of service has the purpose of hosting Data and files
              that enable this Application to run and be distributed as well as
              to provide a ready-made infrastructure to run specific features or
              parts of this Application. Some of these services work through
              geographically distributed servers, making it difficult to
              determine the actual location where the Personal Data are stored.
            </p>
            <h5>CloudFlare</h5>
            <p>
              Cloudflare is a Content Delivery Network (CDN) that improves
              website security and optimizes media distribution over a network
              of global servers. Cloudflare collects usage data when web pages
              of this application are served. Much like Google Analytics, this
              may include IP addresses of website visitors and usage analytics.
            </p>
          </div>
          <p />
          <p />
          <p />
          <p />
        </section>
        <section>
          <h3>The Rights of Users</h3>
          <p>
            Users may exercise certain rights regarding their Data processed by
            the Owner. In particular, Users have the right to do the following:
          </p>
          <ul>
            <li>
              <b>Withdraw their consent at any time.</b> Users have the right to
              withdraw consent where they have previously given their consent to
              the processing of their Personal Data.
            </li>
            <li>
              <b>Access their Data.</b> Users have the right to learn if Data is
              being processed by the Owner, obtain disclosure regarding certain
              aspects of the processing and obtain a copy of the Data undergoing
              processing.
            </li>
            <li>
              <b>Object to processing of their Data.</b> Users have the right to
              object to the processing of their Data if the processing is
              carried out on a legal basis other than consent. Further details
              are provided in the dedicated section below.
            </li>
            <li>
              <b>Verify and seek rectification.</b> Users have the right to
              verify the accuracy of their Data and ask for it to be updated or
              corrected.
            </li>
            <li>
              <b>Restrict the processing of their Data.</b> Users have the
              right, under certain circumstances, to restrict the processing of
              their Data. In this case, the Owner will not process their Data
              for any purpose other than storing it.
            </li>
            <li>
              <b>Have their Personal Data deleted or otherwise removed.</b>{" "}
              Users have the right, under certain circumstances, to obtain the
              erasure of their Data from the Owner.
            </li>
            <li>
              <b>
                Receive their Data and have it transferred to another
                controller.
              </b>{" "}
              Users have the right to receive their Data in a structured,
              commonly used and machine readable format and, if technically
              feasible, to have it transmitted to another controller without any
              hindrance. This provision is applicable provided that the Data is
              processed by automated means and that the processing is based on
              the User’s consent, on a contract which the User is part of or on
              pre-contractual obligations thereof.
            </li>
            <li>
              <b>Lodge a complaint.</b> Users have the right to bring a claim
              before their competent data protection authority.
            </li>
          </ul>
          <h5>Details about the right to object to processing</h5>
          <p>
            Where Personal Data is processed for a public interest, in the
            exercise of an official authority vested in the Owner or for the
            purposes of the legitimate interests pursued by the Owner, Users may
            object to such processing by providing a ground related to their
            particular situation to justify the objection.
          </p>
          <p>
            Users must know that, however, should their Personal Data be
            processed for direct marketing purposes, they can object to that
            processing at any time without providing any justification. To
            learn, whether the Owner is processing Personal Data for direct
            marketing purposes, Users may refer to the relevant sections of this
            document.
          </p>
          <h5>How to exercise these rights</h5>
          <p>
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. These
            requests can be exercised free of charge and will be addressed by
            the Owner as early as possible and always within one month.
          </p>
        </section>
        <section>
          <h3>Cookie Policy</h3>
          <p>
            This Application uses Cookies. To learn more and for a detailed
            cookie notice, the User may consult the{" "}
            <a href="/cookie-policy">Cookie Policy</a>.
          </p>
        </section>
        <section>
          <h3>Additional information about Data collection and processing</h3>
          <h5>Legal Action</h5>
          <p>
            The User’s Personal Data may be used for legal purposes by the Owner
            in Court or in the stages leading to possible legal action arising
            from improper use of this Application or the related Services. The
            User declares to be aware that the Owner may be required to reveal
            personal data upon request of public authorities.
          </p>
          <h5>Additional information about User’s Personal Data</h5>
          <p>
            In addition to the information contained in this privacy policy,
            this Application may provide the User with additional and contextual
            information concerning particular Services or the collection and
            processing of Personal Data upon request.
          </p>
          <h5>System logs and maintenance</h5>
          <p>
            For operation and maintenance purposes, this Application and any
            third-party services may collect files that record interaction with
            this Application (System logs) use other Personal Data (such as the
            IP Address) for this purpose.
          </p>
          <h5>Information not contained in this policy</h5>
          <p>
            More details concerning the collection or processing of Personal
            Data may be requested from the Owner at any time. Please see the
            contact information at the beginning of this document.
          </p>
          <h5>How “Do Not Track” requests are handled</h5>
          <p>
            This Application does not support “Do Not Track” requests. To
            determine whether any of the third-party services it uses honor the
            “Do Not Track” requests, please read their privacy policies.
          </p>
          <h5>Changes to this privacy policy</h5>
          <p>
            The Owner reserves the right to make changes to this privacy policy
            at any time by giving notice to its Users on this page and possibly
            within this Application and/or – as far as technically and legally
            feasible – sending a notice to Users via any contact information
            available to the Owner. It is strongly recommended to check this
            page often, referring to the date of the last modification listed at
            the bottom.
          </p>
          <p>
            Should the changes affect processing activities performed on the
            basis of the User’s consent, the Owner shall collect new consent
            from the User, where required.
          </p>
          <h5>Legal Information</h5>
          <p>
            This privacy statement has been prepared based on provisions of
            multiple legislations, including Art. 13/14 of Regulation (EU)
            2016/679 (General Data Protection Regulation).
          </p>
          <p>
            This privacy policy relates solely to this Application, if not
            stated otherwise within this document.
          </p>
          <p className="pp-update-date">
            <em>Last Updated: July 2, 2019</em>
          </p>
        </section>
      </div>
    </PageContent>
  </>
)

export default PrivacyPolicy
